import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Header from "../sections/Header";
import Feature from "../sections/Feature";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

class Home extends React.Component {

    constructor(props) {
      super();
      this.props = props;
    }

    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>
              <Nav current={this.props.location.pathname} />
              <div class="bg-gray-200 pt-12 sm:pt-16">
                <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div class="max-w-4xl mx-auto text-center">

                    <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                      <span className="text-indigo-500">Our Story</span>
                    </h2>

                  </div>

                  <div>


                    <p className="mt-3 max-w-md mx-auto text-base text-gray-800 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                      GLaDOS operates an end-to-end global edge network, which monitors in real-time and selects the most efficient routing automatically.
                    </p>

                    <p className="mt-3 max-w-md mx-auto text-base text-gray-800 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                      GLaDOS is dedicated to extreme low latency, high availability within and across borders.
                    </p>

                    <p className="mt-3 max-w-md mx-auto text-base text-gray-800 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
We believe that technology is changing the way we live, work and get things done. Our goal is to create apps and services that unlock creativity and productivity in all of us.
                    </p>

                  </div>
                </div>
                <div class="bg-gray-200 mt-10 pb-12 bg-white sm:pb-16">
                  <div class="relative">
                    <div class="absolute inset-0 h-1/2 bg-gray-50"></div>
                    <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div class="max-w-4xl mx-auto">
                        <div class="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                          <div class="border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                            <p class="text-5xl leading-none font-extrabold text-indigo-600">
                              2017
                            </p>
                            <p class="mt-2 text-lg leading-6 font-medium text-gray-500">
                              Started
                            </p>
                          </div>
                          <div class="border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                            <p class="text-5xl leading-none font-extrabold text-indigo-600">
                              New York
                            </p>
                            <p class="mt-2 text-lg leading-6 font-medium text-gray-500">
                              Office
                            </p>
                          </div>
                          <div class="border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                            <p class="text-5xl leading-none font-extrabold text-indigo-600">
                              99.99%
                            </p>
                            <p class="mt-2 text-lg leading-6 font-medium text-gray-500">
                              SLA
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Home );
