import React from "react";

const Page = () => (
  <div className="bg-grey-50">
    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div className="xl:grid xl:grid-cols-3 xl:gap-8">
        <div className="xl:col-span-1">
          <img className="h-12" src="/images/logo-cloud.png" alt="GLaDOS" />
          <p className="mt-8 text-gray-500 text-base leading-6">
           Reliable Global Edge Network
          </p>
        </div>
        <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
          <div className="md:grid md:grid-cols-2 md:gap-8">

            <div className="mt-12 md:mt-0">
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Product
              </h4>
              <ul className="mt-4">
                <li>
                  <a href="/pricing" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Pricing
                  </a>
                </li>
                <li className="mt-4">
                  <a href="/softwares" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Softwares
                  </a>
                </li>
                <li className="mt-4">
                  <a href="/education" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Education
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Support
              </h4>
              <ul className="mt-4">
                <li className="mt-4">
                  <a href="/console/client" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Chat
                  </a>
                </li>
                <li className="mt-4">
                  <a href="mailto:support@glados.network" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Tools
              </h4>
              <ul className="mt-4">
                <li>
                  <a href="https://ifconfig.me" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    What's my IP address
                  </a>
                </li>
                <li className="mt-4">
                  <a href="https://fast.com" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    Speed test
                  </a>
                </li>
              </ul>
            </div>
            <div className="mt-12 md:mt-0">

              <h4 className="text-sm leading-5 font-semibold tracking-wider text-gray-400 uppercase">
                Company
              </h4>
              <ul className="mt-4">
                <li className="mt-4">
                  <a href="/about" className="text-base leading-6 text-gray-500 hover:text-gray-900">
                    About
                  </a>
                </li>
              </ul>

            </div>
          </div>
        </div>
      </div>

      <div className="mt-12 border-t border-gray-200 pt-8">
        <p className="text-base leading-6 text-gray-400 xl:text-center">
          &copy; 2020 Aperture, Inc. All rights reserved.
        </p>
      </div>
    </div>
  </div>
);

export default Page;
