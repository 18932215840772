import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

const Header = ( { loggedIn } ) => (
    <div className="bg-blue-100">

      <div className="relative bg-gray-50 overflow-hidden">

        <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">

          <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <div className="text-center" style={{paddingTop:"50px"}}>
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                Master your network with <span className="text-blue-500">GLaDOS</span>
              </h2>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                GLaDOS provides most fast, stable, and secure endpoints for users to access high speed inter-national Internet, Netflix and Privacy with confidence.
              </p>
              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8"  style={{marginBottom:"180px"}}>
                <div className="rounded-md shadow">
                  <a href="/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Register
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a href="/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-blue-500">
        <div className=" max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8 lg:py-20">
          <div className="mt-10 max-w-4xl mx-auto text-center">
            <h2 className="text-3xl leading-2 font-extrabold text-white sm:text-4xl sm:leading-10">
              Generic Local and Destination Operating System
            </h2>
            <p className="mt-3 text-xl leading-7 text-blue-200 sm:mt-4">
              Smart routing system trusted by users and teams all over the world
            </p>
          </div>
          <div className="mt-10  mb-20 text-center sm:max-w-3xl sm:mx-auto sm:grid sm:grid-cols-3 sm:gap-8">
            <div>
              <p className="text-5xl leading-none font-extrabold text-white">
                99.99%
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-blue-200">
                SLA
              </p>
            </div>
            <div className="mt-10 sm:mt-0">
              <p className="text-5xl leading-none font-extrabold text-white">
                { Math.round((new Date().getTime() - 1484064000000)/(24*60*60*1000)) }
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-blue-200">
                Days Uptime
              </p>
            </div>
            <div className="mt-10 sm:mt-0">
              <p className="text-5xl leading-none font-extrabold text-white">
                200+
              </p>
              <p className="mt-2 text-lg leading-6 font-medium text-blue-200">
                Servers
              </p>
            </div>
          </div>
          <div>


          </div>
        </div>
      </div>

      <div className="relative bg-gray-100 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative max-w-7xl mx-auto sm:px-6 ">
          <div className="text-center">
            <h1 className="text-5xl leading-10 tracking-tight font-extrabold text-gray-800 sm:text-5xl sm:leading-10 sm:text-5xl sm:leading-none md:text-6xl">
              One account to rule them all
            </h1>
            <p className="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              Connect from any platform using one same account.
            </p>
          </div>
        </div>

            <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
              <div className="grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4">
                <div className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1">
                  <svg id="windows"  style={{"color":"#333","width":"96px","marginTop":"-8px", "verticalAlign": "middle"}}   viewBox="0 0 36 24">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18.7954 2.08851C20.3723 0.0101142 22.5646 0 22.5646 0C22.5646 0 22.8906 1.95403 21.3241 3.8364C19.6514 5.8464 17.7501 5.51751 17.7501 5.51751C17.7501 5.51751 17.3931 3.93677 18.7954 2.08851V2.08851ZM17.9507 6.88629C18.7619 6.88629 20.2675 5.77114 22.2273 5.77114C25.6007 5.77114 26.9279 8.17157 26.9279 8.17157C26.9279 8.17157 24.3323 9.4986 24.3323 12.7186C24.3323 16.3511 27.5657 17.6031 27.5657 17.6031C27.5657 17.6031 25.3054 23.9648 22.2524 23.9648C20.8502 23.9648 19.7601 23.0199 18.2826 23.0199C16.7771 23.0199 15.283 24 14.3098 24C11.522 24.0001 8 17.9653 8 13.1144C8 8.34171 10.9811 5.838 13.7773 5.838C15.5951 5.838 17.0057 6.88629 17.9507 6.88629V6.88629Z" fill="currentColor"></path>
        </svg>
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1">
        <svg id="macos" viewBox="0 0 36 24" style={{"color":"#333","width":"96px","marginRight":"0px", "verticalAlign": "middle"}} >
          <path fillRule="evenodd" clipRule="evenodd" d="M15.8794 12.7011C16.0168 10.3047 17.0395 6.40098 19.2618 2H8C6.89543 2 6 2.89543 6 4V20C6 21.1046 6.89543 22 8 22H19.3675C19.1836 20.8126 19.1204 19.6274 19.1251 18.5173C18.6765 18.5758 18.2212 18.6074 17.7647 18.6074C16.1518 18.6074 14.553 18.2135 13.2161 17.6272C11.8949 17.0477 10.7514 16.2451 10.1175 15.3648C9.88356 15.0399 9.95732 14.5868 10.2823 14.3528C10.6072 14.1189 11.0603 14.1926 11.2942 14.5176C11.7192 15.1078 12.6052 15.7759 13.7986 16.2993C14.9764 16.8159 16.3776 17.1574 17.7647 17.1574C18.2344 17.1574 18.7057 17.1182 19.1695 17.0463C19.2362 15.825 19.3653 14.7574 19.4682 13.9668H17.0882C16.4229 13.9668 15.8383 13.4198 15.8794 12.7011ZM20.578 18.2311C21.1884 18.0702 21.7723 17.8645 22.3133 17.6272C23.6346 17.0477 24.7781 16.2451 25.4119 15.3648C25.6459 15.0399 25.5721 14.5868 25.2472 14.3528C24.9222 14.1189 24.4691 14.1926 24.2352 14.5176C23.8102 15.1078 22.9243 15.7759 21.7309 16.2993C21.3857 16.4507 21.0213 16.587 20.6438 16.7041C20.7199 15.5944 20.844 14.6233 20.9397 13.8982C21.0365 13.164 20.4648 12.5168 19.7277 12.5168H17.346C17.5383 10.2196 18.5895 6.37024 20.8934 2H28C29.1046 2 30 2.89543 30 4V20C30 21.1046 29.1046 22 28 22H20.8358C20.6199 20.723 20.5588 19.4319 20.578 18.2311ZM12.5 6.275C12.9004 6.275 13.225 6.59959 13.225 7V9.35294C13.225 9.75335 12.9004 10.0779 12.5 10.0779C12.0996 10.0779 11.775 9.75335 11.775 9.35294V7C11.775 6.59959 12.0996 6.275 12.5 6.275ZM24.225 7C24.225 6.59959 23.9004 6.275 23.5 6.275C23.0996 6.275 22.775 6.59959 22.775 7V9.35294C22.775 9.75335 23.0996 10.0779 23.5 10.0779C23.9004 10.0779 24.225 9.75335 24.225 9.35294V7Z" fill="currentColor"></path>
        </svg>
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1">
            <svg focusable="false" style={{"color":"#333","width":"96px","marginRight":"8px", "verticalAlign": "middle"}}  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"></path></svg>
                </div>
                <div className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1">
                  <svg className="d-inlineBlock mb-1" viewBox="0 0 36 24" style={{"color":"#333","width":"108px","marginRight":"0px", "verticalAlign": "middle"}} >
          <path d="M6.5 3.27934L15.9055 1.99281V11.051H6.5V3.27934ZM17.0384 1.81427L29.5 0V10.9749H17.0384V1.81427ZM6.5 12.0094H15.9055V21.0938L6.5 19.7811V12.0094ZM17.0384 12.1301H29.5V23L17.0384 21.2409" fill="currentColor"></path>
                </svg>
                </div>
                <div className="hidden col-span-2 flex justify-center md:col-span-1 lg:col-span-1">
          <svg id="linux" viewBox="0 0 36 24" style={{"color":"#333","width":"98px","marginRight":"0px", "verticalAlign": "middle"}} >
            <path fillRule="evenodd" clipRule="evenodd" d="M6 3.5C6 2.67157 6.67157 2 7.5 2H27.5C28.3284 2 29 2.67157 29 3.5V20.5C29 21.3284 28.3284 22 27.5 22H7.5C6.67157 22 6 21.3284 6 20.5V3.5ZM8.96967 4.96967C9.26256 4.67678 9.73744 4.67678 10.0303 4.96967L13.5303 8.46967C13.8232 8.76256 13.8232 9.23744 13.5303 9.53033L10.0303 13.0303C9.73744 13.3232 9.26256 13.3232 8.96967 13.0303C8.67678 12.7374 8.67678 12.2626 8.96967 11.9697L11.9393 9L8.96967 6.03033C8.67678 5.73744 8.67678 5.26256 8.96967 4.96967ZM13 15.2C12.5582 15.2 12.2 15.5582 12.2 16C12.2 16.4418 12.5582 16.8 13 16.8H20.5C20.9418 16.8 21.3 16.4418 21.3 16C21.3 15.5582 20.9418 15.2 20.5 15.2H13Z" fill="currentColor"></path>
          </svg>
                </div>
              </div>
            </div>

      </div>


      <div className="bg-gray-50 overflow-hidden">

        <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-16 lg:px-8">

          <svg className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="8b1b5f72-e944-4457-af67-0c6d15a99f38" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
            <div className="lg:col-span-1">
              <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                Always online Edge Network
              </h3>
              <p className="mt-2 text-base leading-6 text-gray-500">
                GLaDOS is designed to provide a stable service. We implement technologies like smart routing, realtime monitors, world wide datacenters and cutting edge encryptions.
              </p>
            </div>
            <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
              <div>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">Real-time Network Monitoring</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    GLaDOS routing its traffic based on real-time network conditions.
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">World wide distribution</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    GLaDOS distributes its endpoints globally to provide best speed from adjacent data centers and reduce upstream downtime risk.
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">Cross Platforms</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    GLaDOS provides its endpoints for Windows, MacOS, Linux, iOS, Andriod and Routers.
                  </p>
                </div>
              </div>
              <div className="mt-10 sm:mt-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                  <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                  </svg>
                </div>
                <div className="mt-5">
                  <h5 className="text-lg leading-6 font-medium text-gray-900">ISP based routing</h5>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    GLaDOS routes your traffic based on your ISP to reduces latency and server load.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
);

const mapStateToProps = ( state ) => ( {
    loggedIn: state.loggedIn,
} );

export default connect( mapStateToProps )( Header );
