import Manager   from "./components/pages/Login.Manager";
import Device    from "./components/pages/Login.device";

import Home      from "./components/pages/Home";
import About     from "./components/pages/About";
import SMS       from "./components/pages/SMS";
import Apply     from "./components/pages/Apply";
import Status    from "./components/pages/Status";
import Pricing   from "./components/pages/Pricing";
import Softwares from "./components/pages/Softwares";
import Education from "./components/pages/Education";
import Services  from "./components/pages/Services";
import Register  from "./components/pages/Register";
import Login     from "./components/pages/Login";

import Qusu_Home     from "./components/qusu/Home.Qusu";
import Qusu_Login    from "./components/qusu/Login.Qusu";

export default [{
        path: "/",
        component: Home,
        exact: true,
    },
    {
        path: "/index",
        component: Manager,
        exact: true,
    },
    {
        path: "/device/:token",
        component: Device,
        exact: true,
    },
    {
        path: "/home",
        component: Qusu_Home,
        exact: true,
    },
    {
        path: "/enter",
        component: Qusu_Login,
        exact: true,
    },
    {
        path: "/pricing",
        component: Pricing,
        exact: true,
    },
    {
        path: "/services",
        component: Services,
        exact: true,
    },
    {
        path: "/education",
        component: Education,
        exact: true,
    },
    {
        path: "/status",
        component: Status,
        exact: true,
    },
    {
        path: "/softwares",
        component: Softwares,
        exact: true,
    },
    {
        path: "/register",
        component: Register,
        exact: true,
    },
    {
        path: "/apply",
        component: Apply,
        exact: true,
    },
    {
        path: "/sms",
        component: SMS,
        exact: true,
    },
    {
        path: "/login",
        component: Login,
        exact: true,
    },
    {
        path: "/about",
        component: About,
        exact: true,
    }
];
