import React          from "react";
import {Fragment}     from "react";
import axios          from 'axios';
import { connect }    from "react-redux";
import { fetchData }  from "../../store";
import Nav            from "../sections/Nav";
import Footer         from "../sections/Footer";

class Page extends React.Component {

    constructor(props) {

      super();
      this.state = {
        userId:0,
        seconds:0,
        email:"",
        mailcode:"",
        error: false,
        msg: '',
        loading: false
      }

      this.sendCode       = this.sendCode.bind(this)
      this.handleChange   = this.handleChange.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)

    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    verify(){
    }

    sendCode(e){

        const {email} = this.state;

        if(this.state.seconds > 0){
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorization', {address:email,site:"glados.network"}).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    warn: true,
                    success: false,
                    msg: res.data.message || 'email address error',
                });
            } else {
                this.setState({
                    success: true,
                    warn: false,
                    msg: res.data.message || 'access code sent. please check mailbox'
                });
                return
            }

            this.setState({seconds:20})
            const countdown = () => {
              var left = this.state.seconds;
              if(left > 0){
                this.setState({seconds: left - 1})
                setTimeout(countdown,1000);
              }
            }

            countdown();

        });

    }

    componentDidMount( ) {

      axios.get("/user/info").then(function(res){
        if(res.data.code == 0){
          window.location.href = "/console"
        }
      });

    }

    handleKeyPress(e){
      //console.log("key",e);
      //e.preventDefault();
      //return false;
    }

    handleSubmit(e) {
        e.preventDefault();
        const {email, mailcode} = this.state;

        console.log(e,e.target,email,mailcode)

        //check
        if(!email && !mailcode){
          this.setState({warn: false, success: false, error: false});
          return;
        }

        if(!email || !mailcode){
          this.setState({warn: true, success: false, error: false, msg: 'please enter your email and access code'});
          return;
        }

        if(this.state.loading == true){
          return;
        }else{
          this.setState({loading: true});
        }

        axios.post('/login', {
            method:"email",
            site:"glados.network",
            email:email.toLowerCase(),
            mailcode,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || 'login failed',
                    error: true,
                    warn:true,
                    loading: false,
                });
            } else {
                this.setState({
                    userId: res.data.data.userId,
                    warn:false,
                    success:true,
                    loading: false,
                });

                window.localStorage.setItem("user_id",res.data.data.userId)

                //redirect
                window.location.href = "/console"
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }

    render( ) {

        return (
          <div>
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">

              <a href="/">
                <img className="mx-auto h-12 w-auto" src="/images/logo-cloud.png" alt="GLaDOS" />
              </a>

              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Login your account
              </h2>
              <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
                Or <a href="/register" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                  start your free trial
                </a>
              </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onKeyPress={this.handleKeyPress} onSubmit={this.handleSubmit} >
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input name="email" value={this.state.email}  onChange={this.handleChange} id="email" type="email" required className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline" />
                    </div>
                  </div>

                  <div className="mt-6">

                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Access Code {this.state.seconds > 0 ? <span>
                          {this.state.seconds} seconds to resend
                        </span>:
                        <button onClick={(e)=>{
                          e.preventDefault();
                          this.sendCode();
                         }} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                          send access code to email
                        </button>
                      }
                    </label>

                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="mailcode" name="mailcode" value={this.state.mailcode} onChange={this.handleChange} type="text" className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline" />


                    </div>
                  </div>

                  {this.state.warn?
                  <div className="mt-6">
                    <p className="mt-2 text-sm text-red-600">{this.state.msg}</p>
                  </div>:null}

                  {this.state.success?
                  <div className="mt-6">
                    <p className="mt-2 text-sm text-green-600">{this.state.msg}</p>
                  </div>:null}

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                      {this.state.loading?"Please Wait":"Login"}
                      </button>
                    </span>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>
        );

    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
