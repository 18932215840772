import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Header from "../sections/Header";
import Feature from "../sections/Feature";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

class Page extends React.Component {

    constructor(props){
      super()
      this.props = props;
    }

    componentDidMount( ) {
    }

    render( ) {

        return (
          <div>
            <Nav current={this.props.location.pathname} />
            <div className="bg-blue-100">

      <div className="relative bg-gray-50 overflow-hidden">

        <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">

          <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 xl:mt-28">
            <div className="text-center" style={{paddingTop:"50px"}}>
              <h3 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                We help students and researchers to <br/>unlock the power of <span className="text-blue-500">Internet</span>
              </h3>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-600 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                So they can focus on researching and learning things.
              </p>

              <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8"  style={{marginBottom:"100px"}}>
                <div className="rounded-md shadow">
                  <a href="/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Register
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a href="/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-600 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline-blue transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10">
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


              </div>

              <div class="bg-gray-100">
                <div class="pt-12 sm:pt-16 lg:pt-20">
                  <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="text-center">
                      <h2 class="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                      Education Plan
                      </h2>
                      <p class="mt-4 text-xl leading-7 text-gray-500">
                        equip students with first class networking tools.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-8 bg-grey-200 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
                  <div class="relative">
                    <div class="absolute inset-0 h-1/2 bg-gray-100"></div>
                    <div class="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div class="max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex">
                        <div class="bg-white px-6 py-8 lg:flex-shrink-1 lg:p-12">
                          <h3 class="text-2xl leading-8 font-extrabold text-gray-900 sm:text-3xl sm:leading-9">
                            Open to Education
                          </h3>
                          <p class="mt-6 text-base leading-6 text-gray-500">
                          GLaDOS runs Education Plan from 2019, used by 10K+ students.
                          </p>
                          <div class="mt-8">
                            <div class="flex items-center">
                              <h4 class="flex-shrink-0 pr-4 bg-white text-sm leading-5 tracking-wider font-semibold uppercase text-indigo-600">
                                What's included
                              </h4>
                              <div class="flex-1 border-t-2 border-gray-200"></div>
                            </div>
                            <ul class="mt-8 lg:grid lg:grid-cols-2 lg:col-gap-8 lg:row-gap-5">
                              <li class="flex items-start lg:col-span-1">
                                <div class="flex-shrink-0">
                                  <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                                  </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                  1 year education membership with no tricks
                                </p>
                              </li>
                              <li class="flex items-start lg:col-span-1">
                                <div class="flex-shrink-0">
                                  <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                                  </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                30 GB monthly traffic, reset every month
                                </p>
                              </li>
                              <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                                <div class="flex-shrink-0">
                                  <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                                  </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                  Sci-hub supported
                                </p>
                              </li>
                              <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                                <div class="flex-shrink-0">
                                  <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                                  </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                  Special offering to medical school students
                                </p>
                              </li>
                              <li class="mt-5 flex items-start lg:col-span-1 lg:mt-0">
                                <div class="flex-shrink-0">
                                  <svg class="h-5 w-5 text-green-400" fill="currentColor" viewBox="0 0 20 20">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                                  </svg>
                                </div>
                                <p class="ml-3 text-sm leading-5 text-gray-700">
                                  Block fakenews, cult and political content
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12">
                          <p class="text-lg leading-6 font-medium text-gray-900">
                            Apply with your email
                          </p>
                          <div class="mt-4 flex items-center justify-center text-5xl leading-none font-extrabold text-gray-900">
                            <span>
                              Free
                            </span>
                          </div>
                          <p class="mt-4 text-sm leading-5">
                            <a href="/console/education" class="font-medium text-gray-500 underline">
                              Learn about our product policy
                            </a>
                          </p>
                          <div class="mt-6">
                            <div class="rounded-md shadow">
                              <a href="/console/education" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                Get Access
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            <div className="bg-gray-200">
                <div className="text-black max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">

                  <h2 class="mb-10 text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                    Build an open society
                  </h2>

                  <p>GLaDOS Education helps students, teachers, and schools find the tools and resources they need to master their network, and get unlimited access to knowledge.</p>

                  <p>It is our pleasure to provide students tools for learning and researching technologies and cultures.</p>
                  <p>Firewalls can harm an open mind and open society, meanwhile paywall and many walls do the same thing.</p>
                  <p>GLaDOS Team believes that any sort of walls should not prevent the education. So we are open for education, free to students, teachers and educational applications.
                  </p>
                  <p>
                    We provide an education plan accessable for all students for one year.<br/>
                    For Medical School students, we provide a Pro plan for free in case of special researching usage.
                  </p>
                  <p>Let us build a better and open society for our next generation.</p>
                </div>
            </div>

            <div className="bg-gray-200">
              <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 className="text-3xl leading-9 font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
                  Frequently asked questions
                </h2>
                <div className="mt-6 border-t-2 border-gray-200 pt-6">
                  <dl>
                    <div className="md:grid md:grid-cols-12 md:gap-8">
                      <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                        Why offer medical students Pro plan?
                      </dt>
                      <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base leading-6 text-gray-500">
                          Because medical education and research should not be blocked, and they need more powerful, unlimited tools to download medical databases and papers.
                        </p>
                      </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                      <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                        How to verify a student?
                      </dt>
                      <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base leading-6 text-gray-500">
                          We can not verify. We just ensure you have a school email.
                        </p>
                      </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                      <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                        How to verify a Medical Student?
                      </dt>
                      <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base leading-6 text-gray-500">
                          With the help of wdoms(World Directory of Medical Schools), please ensure your school is listed.
                        </p>
                      </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                      <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                        Is it a sponsored program?
                      </dt>
                      <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base leading-6 text-gray-500">
                          No. We do it because we can.
                        </p>
                      </dd>
                    </div>
                    <div className="mt-8 border-t border-gray-200 pt-6 md:grid md:grid-cols-12 md:gap-8">
                      <dt className="text-base leading-6 font-medium text-gray-900 md:col-span-5">
                        Can I upgrade to a paid plan?
                      </dt>
                      <dd className="mt-2 md:mt-0 md:col-span-7">
                        <p className="text-base leading-6 text-gray-500">
                          Sure, upgrade anytime, your education membership days still counts.
                        </p>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>

            <Footer/>

          </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
