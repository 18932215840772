import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Footer from "../sections/Footer";

class Page extends React.Component {

    constructor(props) {

      super();
      this.state = {
        userId:0,
        seconds:0,
        phone:"",
        country:"cn",
        code:"",
        error: false,
        warn: false,
        msg: '',
        loading: false
      }

      this.sendCode       = this.sendCode.bind(this)
      this.handleChange   = this.handleChange.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)

    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;

      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    sendCode(e){

      e.preventDefault();

      var   countries = {
        "Auto Detect":"cn",
        "Mainland China":"cn",
        "United States":"us",
        "United Kingdom":"uk"
      }

      const {phone,country} = this.state;

      const code = countries[country];

      if(this.state.seconds > 0){
        return;
      }

      if(!phone){
        return alert("please enter your Phone Number");
      }

      axios.post('/verify', {phone,country:code}).then((res) => {
        if (res.data.code !== 0) {
          this.setState({
            warn: true,
            msg: res.data.message
          });
        } else {
          this.setState({
            success: true,
            msg: 'please check your sms'
          });
        }

        this.setState({seconds:20})
        const countdown = () => {
          var left = this.state.seconds;
          console.log("countdown",this.state);
          if(left > 0){
            this.setState({seconds: left - 1})
            setTimeout(countdown,1000);
          }
        }

        countdown();

      });

      return false;

    }

    componentDidMount( ) {
    }

    handleKeyPress(e){
      //console.log("key",e);
      //e.preventDefault();
      //return false;
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("login",this.state);
        const {phone, code} = this.state;
        //check
        if(!phone || !code){
          this.setState({warn: true, success: false, error: false, msg: 'please enter your phone and passcode'});
          return;
        }else{
          this.setState({warn: false, success: false, error: false, msg: ''});
        }
        axios.post('/login', {
            method:"phone",
            site:"glados.network",
            phone,
            code,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || 'login failed',
                    error: true,
                    warn:true,
                    loading: false,
                });
            } else {
                this.setState({
                    userId: res.data.data.userId,
                    loading: false,
                });
                //redirect
                window.location.href = "/console"
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }


    render( ) {

        return (
          <div>
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <a href="/">
                <img className="mx-auto h-12 w-auto" src="/images/logo.png" alt="GLaDOS" />
              </a>
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Sign in to GLaDOS
              </h2>
              <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
                Or <a href="/register" className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                  start your free trial
                </a>
              </p>
            </div>


            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onSubmit={this.handleSubmit}>

                  <div className="mt-1">
                    <label className="block text-sm font-medium leading-8 text-gray-700 sm:mt-px sm:pt-2">
                      Country / Region
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                      <div className="max-w-xs rounded-md shadow-sm">
                        <select onChange={this.handleChange} id="country" name="country" className="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                          <option>Auto Detect</option>
                          <option>Mainland China</option>
                          <option>United States</option>
                          <option>United Kingdom</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6">
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Phone Number
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="phone" onChange={this.handleChange} name="phone" type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  <div className="mt-6">
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Passcode {this.state.seconds > 0 ? <span>
                          {this.state.seconds} seconds to resend
                        </span>:
                        <button onClick={this.sendCode} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                          send passcode to phone
                        </button>
                      }
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="passcode" type="text" name="code" onChange={this.handleChange} className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  {this.state.warn?
                  <div className="mt-6">
                    <p className="mt-2 text-sm text-red-600">{this.state.msg}</p>
                  </div>:null}

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button type="submit"  className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        Login in
                      </button>
                    </span>
                  </div>
                </form>

                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm leading-5">
                      <span className="px-2 bg-white text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div className="mt-6">

                    <div>
                      <span className="w-full inline-flex rounded-md shadow-sm">
                        <a href="/login" type="button" className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
                          Login with Email
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        );

    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
