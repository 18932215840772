import React          from "react";
import {Fragment}     from "react";
import axios          from 'axios';
import { connect }    from "react-redux";
import { fetchData } from "../../store";

class Qusu extends React.Component {

    constructor(props) {
      super();
      this.props = props;
      this.state = {
        userId:0,
        warp:0,
        seconds:0,
        email:"",
        mailcode:"",
        error: false,
        msg: '',
        loading: false
      }
      this.handleChange   = this.handleChange.bind(this)
      this.sendCode       = this.sendCode.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    sendCode(e){

        e.preventDefault();

        const {email} = this.state;

        if(this.state.seconds > 0){
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorization', {address:email,site:"qusu.io"}).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    warn: true,
                    msg: res.data.message || '',
                });
            } else {
                this.setState({
                    success: true,
                    msg: res.data.message || 'please check your email'
                });
            }

            this.setState({seconds:20})
            const countdown = () => {
              var left = this.state.seconds;
              console.log("countdown",this.state);
              if(left > 0){
                this.setState({seconds: left - 1})
                setTimeout(countdown,1000);
              }
            }

            countdown();

        });

    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("login",this.state);
        const {email, mailcode} = this.state;
        //check
        if(!email || !mailcode){
          this.setState({warn: true, success: false, error: false, msg: 'please enter your email and passcode'});
          return;
        }else{
          this.setState({warn: false, success: false, error: false, msg: ''});
        }

        this.setState({warp:1});

        axios.post('/login', {
            method:"email",
            site:"qusu.io",
            email,
            mailcode,
        }).then((res) => {

          if (res.data.code !== 0) {
            this.setState({
              msg: res.data.message || 'login failed',
              error: true,
              warn:true,
              loading: false,
            });
          } else {
            this.setState({
              userId: res.data.data.userId,
              loading: false,
            });
            //redirect
            window.location.href = "/dashboard"

          }

          setTimeout(()=>{
            this.setState({warp:0});
          },1000)

        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }


    componentDidMount( ) {

      //based on an Example by @curran
      window.requestAnimFrame = (function(){   return  window.requestAnimationFrame})();
      var canvas = document.getElementById("space");
      var hero   = document.getElementById("hero");
      var c = canvas.getContext("2d");

      var numStars = 2900;
      var radius = '0.'+Math.floor(Math.random() * 9) + 1  ;
      var focalLength = canvas.width *2;
      var that = this;
      var centerX, centerY;

      var stars = [], star;
      var i;

      var animate = true;
      initializeStars();

      function executeFrame(){

        if(animate){
          requestAnimFrame(executeFrame);
        }
        moveStars();
        drawStars();
      }

      function initializeStars(){
        centerX = canvas.width / 2 * 1.4;
        centerY = canvas.height / 2 * 0.8;

        stars = [];
        for(i = 0; i < numStars; i++){
          star = {
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            z: Math.random() * canvas.width,
            o: '0.'+Math.floor(Math.random() * 99) + 1
          };
          stars.push(star);
        }
      }

      function moveStars(){
        for(i = 0; i < numStars; i++){
          star = stars[i];
          star.z--;

          if(star.z <= 0){
            star.z = canvas.width;
          }
        }
      }

      function drawStars(){
        var pixelX, pixelY, pixelRadius;

        // Resize to the screen
        if(canvas.width != window.innerWidth || canvas.width != window.innerWidth){
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight * 1;
          hero.width  = window.innerWidth;
          hero.height = window.innerHeight * 1;
          initializeStars();
        }

        if(that.state.warp == 0){
          c.fillStyle = "rgba(0,10,20,1)";
          c.fillRect(0,0, canvas.width, canvas.height);
        }

        c.fillStyle = "rgba(209, 255, 255, "+radius+")";
        for(i = 0; i < numStars; i++){
          star = stars[i];

          pixelX = (star.x - centerX) * (focalLength / star.z);
          pixelX += centerX;
          pixelY = (star.y - centerY) * (focalLength / star.z);
          pixelY += centerY;
          pixelRadius = 1 * (focalLength / star.z);

          c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
          //c.fillStyle = "rgba(209, 255, 255, "+star.o+")";
          c.fillStyle = "rgba(209, 255, 255, "+star.o+")";
        }
      }

      executeFrame();

    }

    render( ) {

        return (
          <div class="relative bg-black overflow-hidden">
            <canvas id="space" class="absolute"></canvas>
            <div class="hidden sm:block sm:absolute sm:inset-0">
              <svg class="absolute bottom-0 right-0 transform translate-x-1/2 mb-48 text-gray-700 lg:top-0 lg:mt-28 lg:mb-0 xl:transform-none xl:translate-x-0" width="364" height="384" viewBox="0 0 364 384" fill="none">
                <defs>
                </defs>
                <rect width="364" height="384" fill="url(#eab71dd9-9d7a-47bd-8044-256344ee00d0)" />
              </svg>
            </div>
            <div id="hero" class="relative pt-6 pb-12 sm:pb-32">
              <nav class="relative max-w-screen-xl mx-auto flex items-center justify-between px-4 sm:px-6">
                <div class="flex items-center flex-1">
                  <div class="flex items-center justify-between w-full md:w-auto">
                    <a href="#" aria-label="Home">
                      <img class="h-10 w-auto sm:h-10" src="/logo-dark.png" alt="Logo" />
                    </a>
                  </div>
                </div>
              </nav>

              <main class="mt-8 sm:mt-16 md:mt-20 lg:mt-24">
                <div class="mx-auto max-w-screen-xl">
                  <div class="lg:grid lg:grid-cols-12 lg:gap-8">
                    <div class="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                      <div>
                        <h2 class="mt-4 text-4xl tracking-tight leading-10 font-extrabold text-white sm:mt-5 sm:leading-none sm:text-6xl lg:mt-6 lg:text-5xl xl:text-6xl">
                          曲速加速
                          <br />
                          <span class="text-blue-500">Switch 下载加速器</span>
                        </h2>
                        <p class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                          曲速专业优化 Switch 下载和更新体验
                        </p>
                      </div>
                    </div>
                    <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-span-6">
                      <div class="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                        <div class="px-4 py-8 sm:px-10">

                          <div>
                            <h2 class="mt-1 text-3xl leading-9 font-extrabold text-gray-900">
                              立即注册曲速
                            </h2>
                          </div>

                          <div class="mt-6 relative">
                            <div class="absolute inset-0 flex items-center">
                              <div class="w-full border-t border-gray-300"></div>
                            </div>
                            <div class="relative flex justify-center text-sm leading-5">

                              {this.state.msg?
                              <p class="px-2 bg-white text-gray-500">
                                {this.state.msg}
                              </p>
                                  :
                                  <span class="px-2 bg-white text-gray-500">
                                    免费试用
                                  </span>
                              }
                            </div>
                          </div>

                          <div class="mt-6">
                            <form onSubmit={this.handleSubmit} action="#" method="POST" class="space-y-6">

                              <div>
                                <label className="block text-sm font-medium leading-5 text-gray-700">
                                  电子邮件
                                </label>
                                <div class="mt-2 rounded-md shadow-sm">
                                  <input id="mobile-or-email"  name="email" value={this.state.email}  onChange={this.handleChange} id="email" type="email" required placeholder="Email" required className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline" />
                                </div>
                              </div>

                              <div>
                                <label className="block text-sm font-medium leading-5 text-gray-700">
                                  注册验证码 {this.state.seconds > 0 ? <span>
                                    {this.state.seconds} 秒后重发
                                  </span>:
                                  <button onClick={this.sendCode} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                                    发送验证码到邮箱
                                  </button>
                                  }
                                </label>
                                <div className="mt-2 rounded-md shadow-sm">
                                  <input id="mailcode" name="mailcode" value={this.state.mailcode} onChange={this.handleChange} type="text" className="w-full mt-2 py-2 px-4 bg-white text-gray-700 border border-gray-300 rounded block placeholder-gray-500 focus:border-blue-500 focus:outline-none focus:shadow-outline"  />
                                </div>
                              </div>

                              <div>
                                <span class="block w-full rounded-md shadow-sm">
                                  <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                                    Create your account
                                  </button>
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div class="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
                          <p class="text-xs leading-5 text-gray-500">
                            已经注册 ? 请请在 <a href="/enter" class="font-medium text-gray-900 hover:underline">这里登录</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div class="relative bg-black" style={{background:"#000a10"}}>
              <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <div class="xl:grid xl:grid-cols-3 xl:gap-8"></div>
                <div class="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
                  <div class="flex md:order-2">
                    <a href="#" class="text-gray-400 hover:text-gray-300">
                      <span class="sr-only">Facebook</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
                      </svg>
                    </a>
                    <a href="#" class="ml-6 text-gray-400 hover:text-gray-300">
                      <span class="sr-only">Instagram</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
                      </svg>
                    </a>
                    <a href="#" class="ml-6 text-gray-400 hover:text-gray-300">
                      <span class="sr-only">Twitter</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                    <a href="#" class="ml-6 text-gray-400 hover:text-gray-300">
                      <span class="sr-only">GitHub</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
                      </svg>
                    </a>
                    <a href="#" class="ml-6 text-gray-400 hover:text-gray-300">
                      <span class="sr-only">Dribbble</span>
                      <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                        <path fill-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z" clip-rule="evenodd" />
                      </svg>
                    </a>
                  </div>
                  <p class="mt-8 text-base leading-6 text-gray-400 md:mt-0 md:order-1">
                    &copy; 2020 Qusu, Inc. All rights reserved.
                  </p>
                </div>
              </div>

            </div>
          </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Qusu );
