import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Faq from "../sections/Faq";
import Header from "../sections/Header";
import Service from "../sections/Service";
import Footer from "../sections/Footer";

class Home extends React.Component {
    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>

              <Nav current={this.props.location.pathname} />
              <div class="bg-blue-600">
                <div class="pt-12 px-4 sm:px-6 lg:px-8 lg:pt-20">
                  <div class="text-center">
                    <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                      Pricing
                    </h2>
                    <p class="mt- text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                      Simple and Transparent Pricing
                    </p>
                    <p class="mt-3 max-w-4xl mx-auto text-xl leading-7 text-gray-300 sm:mt-5 sm:text-2xl sm:leading-8">
                      All GLaDOS plans have been designed to fit most people need.
                    </p>
                  </div>
                </div>

                <div class="mt-16 pb-12 lg:mt-20 lg:pb-20">
                  <div class="relative z-0">
                    <div class="absolute inset-0 h-5/6 bg-blue-600 lg:h-2/3"></div>
                    <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div class="relative lg:grid lg:grid-cols-7">
                        <div class="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                          <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                            <div class="flex-1 flex flex-col">
                              <div class="bg-white px-6 py-10">
                                <div>
                                  <h2 class="text-center text-2xl leading-8 font-medium text-gray-900">
                                    Basic
                                  </h2>
                                  <div class="mt-4 flex items-center justify-center">
                                    <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                                      <span class="mt-2 mr-2 text-4xl font-medium">
                                        $
                                      </span>
                                      <span class="font-extrabold">
                                        1.6
                                      </span>
                                    </span>
                                    <span class="text-xl leading-7 font-medium text-gray-400">
                                      /month
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-200 sm:p-10 lg:p-6 xl:p-10">
                                <ul>
                                  <li class="flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      3 Devices<br/>
                                      active simultaneously
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      6 Datacenters
                                      <br/>100+ servers pool
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      Multi Protocols<br/>
                                      - TROJAN<br/>
                                      - VMESS<br/>
                                      - VLESS<br/>
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      200GB Traffic Every Month<br/>
                                      100 Mbps bandwidth limit
                                    </p>
                                  </li>
                                </ul>
                                <div class="mt-8">
                                  <div class="rounded-lg shadow-md">
                                    <a href="/register" data-gumroad-single-product="true" class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                                      Start today
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                          <div class="relative z-10 rounded-lg shadow-xl">
                            <div class="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"></div>
                            <div class="absolute inset-x-0 top-0 transform translate-y-px">
                              <div class="flex justify-center transform -translate-y-1/2">
                                <span class="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                                  Most popular
                                </span>
                              </div>
                            </div>
                            <div class="bg-white rounded-t-lg px-6 pt-12 pb-10">
                              <div>
                                <h2 class="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6">
                                  Pro
                                </h2>
                                <div class="mt-4 flex items-center justify-center">
                                  <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                                    <span class="mt-2 mr-2 text-4xl font-medium">
                                      $
                                    </span>
                                    <span class="font-extrabold">
                                      4.2
                                    </span>
                                  </span>
                                  <span class="text-2xl leading-8 font-medium text-gray-400">
                                    /month
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-200 sm:px-10 sm:py-10">
                              <ul>
                                <li class="flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      6 Devices<br/>
                                      active simultaneously
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                    6 Datacenters<br/>
                                    200+ servers pool<br/>
                                    Premium JP, HK, Taiwan Datacenters<br/>
                                  </p>
                                </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      Multi Protocols<br/>
                                      - TROJAN<br/>
                                      - VMESS<br/>
                                      - VLESS<br/>
                                      - Wireguard<br/>
                                    </p>
                                  </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      500GB Traffic Every Month, Reset on each month<br/>
                                      200 Mbps bandwidth limit
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                    Gaming and Router Support
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                    Tier 1-3 ISP Optimized
                                  </p>
                                </li>
                              </ul>
                              <div class="mt-10">
                                <div class="rounded-lg shadow-md">
                                  <a href="/register" data-gumroad-single-product="true" class="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150">
                                    Start today
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                          <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                            <div class="flex-1 flex flex-col">
                              <div class="bg-white px-6 py-10">
                                <div>
                                  <h2 class="text-center text-2xl leading-8 font-medium text-gray-900">
                                    Team
                                  </h2>
                                  <div class="mt-4 flex items-center justify-center">
                                    <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                                      <span class="mt-2 mr-2 text-4xl font-medium">
                                        $
                                      </span>
                                      <span class="font-extrabold">
                                        18
                                      </span>
                                    </span>
                                    <span class="text-xl leading-7 font-medium text-gray-400">
                                      /month
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-200 sm:p-10 lg:p-6 xl:p-10">
                                <ul>
                                  <li class="flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      30 Devices<br/>
                                      active simultaneously
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      6 Datacenters<br/>
                                      200+ servers pool<br/>
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      Multi Protocols<br/>
                                      - TROJAN<br/>
                                      - VMESS<br/>
                                      - VLESS<br/>
                                      - Wireguard<br/>
                                    </p>
                                  </li>
                                  <li class="mt-4 flex items-start">
                                    <div class="flex-shrink-0">
                                      <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                      </svg>
                                    </div>
                                    <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                      2000GB Traffic Every Month<br/>
                                      200 Mbps bandwidth limit
                                    </p>
                                  </li>
                                </ul>
                                <div class="mt-8">
                                  <div class="rounded-lg shadow-md">
                                    <a href="/register" data-gumroad-single-product="true" class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150">
                                      Start today
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <br/>
                <br/>
              </div>
              <Service />
              <div class="bg-blue-100">
                <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                  <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    GLaDOS is for Specialists
                  </h2>
                  <h3 class="text-2xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                    <span class="text-indigo-600">Build for people who cares about tooling</span>
                  </h3>
                  <p>
                    We build fast and reliable services for Professionals, Engineers, Scientists, Doctors, Artists, Elon Musks, Steve Jobs.<br/>
                    They're specialists, not fond of rules. And they have no respect for the status quo.</p>

                  <div class="mt-10 inline-flex rounded-md shadow">
                    <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                      Get started
                    </a>
                  </div>
                </div>
              </div>

              <Faq />
              <Footer />

            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Home );
