import React          from "react";
import {Fragment}     from "react";
import axios          from 'axios';
import { connect }    from "react-redux";
import { fetchData }  from "../../store";
import Nav            from "../sections/Nav";
import Footer         from "../sections/Footer";

class Page extends React.Component {

    constructor({match}) {

      super();
      this.state = {
        userId:0,
        seconds:0,
        email:"",
        token:match.params.token,
        mailcode:"",
        error: false,
        success: false,
        register: false,
        loading: false,
        msg: '',
        loading: false
      }

      this.sendCode       = this.sendCode.bind(this)
      this.handleChange   = this.handleChange.bind(this)
      this.handleSubmit   = this.handleSubmit.bind(this)
      this.handleKeyPress = this.handleKeyPress.bind(this)

    }

    handleChange(event) {
      const name  = event.target.name;
      const value = event.target.value;
      this.setState({
        [name]: value,
        msg: '',
        error: false,
        success: false,
        warn: false,
      });
    }

    verify(){
    }

    sendCode(e){

        const {email} = this.state;

        if(this.state.seconds > 0){
          return;
        }

        if(!email){
          return alert("please enter your email");
        }

        axios.post('/authorization', {address:email,site:"glados.network"}).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    warn: true,
                    msg: res.data.message || '',
                });
            } else {
                this.setState({
                    success: true,
                    msg: res.data.message || 'please check your email'
                });
            }

            this.setState({seconds:20})
            const countdown = () => {
              var left = this.state.seconds;
              console.log("countdown",this.state);
              if(left > 0){
                this.setState({seconds: left - 1})
                setTimeout(countdown,1000);
              }
            }

            countdown();

        });

    }

    componentDidMount( ) {
    }

    handleKeyPress(e){
      //console.log("key",e);
      //e.preventDefault();
      //return false;
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("login",this.state);
        const {email, mailcode, token} = this.state;
        //check
        if(!email || !mailcode){
          this.setState({warn: true, success: false, error: false, msg: 'please enter your email and passcode'});
          return;
        }else{
          this.setState({warn: false, success: false, error: false, msg: '', loading:true});
        }

        axios.post('/device', {
            method:"email",
            site:"glados.network",
            token,
            email,
            mailcode,
        }).then((res) => {
            if (res.data.code !== 0) {
                this.setState({
                    msg: res.data.message || 'login failed',
                    error: true,
                    register:true,
                    warn:true,
                    loading: false,
                });
            } else {
                this.setState({
                    userId: res.data.data.userId,
                    success:true,
                    loading: false,
                });

                setTimeout(()=>{
                  window.close()
                },1000)
                //redirect
                //window.location.href = "/console"
            }
        }).catch((err) => {
            console.error(err);
            this.setState({
                msg: 'login error',
                error: true,
                warn:true,
                loading: false,
            });
        });

    }

    renderSuccess(){

      return (
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <a href="/">
                <img className="mx-auto h-12 w-auto" src="/images/logo-cloud.png" alt="GLaDOS" />
              </a>

              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-indigo-900">
                Authentication Success
              </h2>
              <p className="mt-4 text-center text-xl leading-1 text-gray-600 max-w">
                Window is closing.
              </p>
            </div>
          </div>
      )
    }

    renderLoading(){
      return (
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <a href="/">
                <img className="mx-auto h-12 w-auto" src="/images/logo-cloud.png" alt="GLaDOS" />
              </a>
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Loading
              </h2>
            </div>
          </div>
      )
    }


    render( ) {

        if(this.state.success){
          //window.location.href = "/console/device"
          return this.renderSuccess();
        }

        if(this.state.loading){
          return this.renderLoading();
        }

        return (
          <div>
          <div className="min-h-screen bg-gray-200 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">

              <a href="/">
                <img className="mx-auto h-12 w-auto" src="/images/logo-cloud.png" alt="GLaDOS" />
              </a>

              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-900">
                Authentication
              </h2>

            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">

              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form onKeyPress={this.handleKeyPress} onSubmit={this.handleSubmit} >
                  <div>
                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1 rounded-md shadow-sm">
                      <input name="email" value={this.state.email}  onChange={this.handleChange} id="email" type="email" required className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  <div className="mt-6">

                    <label className="block text-sm font-medium leading-5 text-gray-700">
                      Passcode {this.state.seconds > 0 ? <span>
                          {this.state.seconds} seconds to resend
                        </span>:
                        <button onClick={this.sendCode} className="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">
                          send passcode to email
                        </button>
                      }
                    </label>

                    <div className="mt-1 rounded-md shadow-sm">
                      <input id="mailcode" name="mailcode" value={this.state.mailcode} onChange={this.handleChange} type="text" className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5" />
                    </div>
                  </div>

                  {this.state.warn?
                  <div className="mt-6">
                    <p className="mt-2 text-sm text-red-600">{this.state.msg}</p>
                  </div>:null}

                  <div className="mt-6">
                    <span className="block w-full rounded-md shadow-sm">
                      <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                        Login
                      </button>
                    </span>
                  </div>
                </form>

                <div className="mt-6">
                  <div className="relative"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
