import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Header from "../sections/Header";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

class Page extends React.Component {

    constructor(props) {
      super();
      this.props = props;
      this.state = {
        code:0,
        submit:false
      }
    }

    componentDidMount( ) {
    }

    handleChange(e){
      this.setState({
        address:e.target.value
      })
    }

    handleSubmit(e){

      e.preventDefault()

      this.setState({
        submit:true
      });

      axios.post('/apply', {address:this.state.address}).then((res) => {
          const {data} = res;
          this.setState({
            code:data.data.code,
          });
      });

    }

    render( ) {

        return (
            <div>

              <div className="bg-gray-200" >
                      <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                  <header className="py-10">

                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                      <h1 className="text-5xl leading-9 font-bold text-black">
                        GLaDOS 试用申请
                      </h1>
                      <br/>
                      <p>GLaDOS 是一个稳定的VPN网络，支持自动测速和切换, 您可以通过iOS/Mac/Windows/Android连接。</p>
                      <p>填写您的电邮，我们会发送到您的邮箱注册链接(含有激活码)，请在PC或者Mac打开可以获得30天试用。</p>
                      <br/>
                      <br/>
                    </div>
                  </header>

                  {this.state.submit?
                  <div className="bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                    <div>
                      <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                        <svg className="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                        </svg>
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                          申请成功
                        </h3>
                        <div className="mt-2">
                          <p className="text-sm leading-5 text-gray-500">
                            我们建议您在电脑上查看邮件并注册
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <span className="flex w-full rounded-md shadow-sm">
                        <a href={this.state.code == 0 ? "/" : `/landing/${this.state.code}`} type="button" className="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                          现在访问GLaDOS
                        </a>
                      </span>
                    </div>
                    </div>:<div className="bg-white">

                    <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                      <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        立即获得30天
                        <span className="text-indigo-600" id="newsletter-headline">试用</span>
                      </h2>
                      <form onSubmit={(e)=>{this.handleSubmit(e)}}   className="mt-8 sm:flex" aria-labelledby="newsletter-headline">
                        <input onChange={(e)=>{this.handleChange(e)}} aria-label="Email address" type="email" required className="appearance-none w-full px-5 py-3 border border-gray-300 text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:max-w-xs" placeholder="Enter your email" />
                        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                          <button className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                            提交
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  }

                </div>

                <main className="-mt-32">
                </main>
              </div>

            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
