import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const Container = (props) => {

  const [login, setLogin] = useState(false);
  var current = props.current || "/";
  var items = [{
    display:"GLaDOS",
    path:"/",
  },{
    display:"Pricing",
    path:"/pricing",
  },{
    display:"Services",
    path:"/services",
  }];

  //only on client side
  useEffect(() => {
    axios.get("/user/info").then(function(res){
      if(res.data.code == 0){
        setLogin(true)
      }
    });
  });


  items = items.map((item,idx)=>{
    var klass = "ml-8 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium leading-5 text-gray-900 focus:outline-none focus:border-blue-700 transition duration-150 ease-in-out";

    if(item.path == current){
      klass = klass + " border-blue-800"
    }else{
      klass = klass + " border-transparent"
    }

    return (<Link key={idx} to={item.path} className={klass}>
      {item.display}
    </Link>
    )
  });

return (
  <nav  className="bg-white shadow" >
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <div className="flex-shrink-0 flex items-center">
            <img className="block lg:hidden h-10 w-auto" src="/images/logo-new.png" alt="" />
            <img className="hidden lg:block h-10 w-auto" src="/images/logo-new.png" alt="" />
          </div>
          <div className="hidden sm:ml-6 sm:flex">
            {items}
          </div>
        </div>

        <div className="flex items-center">
          <div className="flex-shrink-0">
            {login?
            <a href="/console" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-blue-600 ">
              <span>Dashboard</span>
            </a>
                :
            <a href="/login" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-blue-600 ">
              <span>Get started</span>
            </a>
              }
          </div>
        </div>
      </div>
    </div>
  </nav>
);



}

export default Container;
