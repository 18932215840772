import React from "react";

const Page = () => (
  <div className="bg-gray-100">
  <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-20 lg:pb-28 lg:px-8">
    <h2 className="text-3xl leading-9 font-extrabold text-gray-900">
      Frequently asked questions
    </h2>
    <div className="mt-6 border-t-2 border-gray-100 pt-10">
      <dl className="md:grid md:grid-cols-2 md:gap-8">
        <div>
          <div>
            <dt className="text-lg leading-6 font-medium text-gray-900">
              Oh, I can not use GLaDOS, is your server down?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                Calm down and Dont panic. GLaDOS is so hard to have downtime, most of time it is user side problem. Please run automatic diagnose after you login to GLaDOS dashboard.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              Do you offer a free trial?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                  Yes, we offer a fully-featured free trial for new accounts.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              Does GLaDOS support Netfix?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                Yes. GLaDOS supports Netfix. You can unlock Netfix from China Taiwan or United States Region.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              Does GLaDOS log my traffic?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                No. We will not log users traffic. GLaDOS is a privacy-focused company that does not store activity logs or connection logs of our users. GLaDOS does collect minimal information about the usage of our services in order to identify and address technical issues, but this information cannot be used to connect you to any specific activity or behavior.
              </p>
            </dd>
          </div>
        </div>
        <div className="mt-12 md:mt-0">
          <div>
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What does GLaDOS mean?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                Generic Local and Destination Operating System.
              </p>
            </dd>
          </div>
          <div className="mt-12">
            <dt className="text-lg leading-6 font-medium text-gray-900">
              What is Proxy?
            </dt>
            <dd className="mt-2">
              <p className="text-base leading-6 text-gray-500">
                Connecting to a Proxy gives you an encrypted connection to the internet. This allows you to stay private, stay secure and access the online content you want—no matter where you are.
              </p>
            </dd>
          </div>
        </div>
      </dl>
    </div>
  </div>
</div>
);

export default Page;
