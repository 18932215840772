import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Faq from "../sections/Faq";
import Header from "../sections/Header";
import Service from "../sections/Service";
import Footer from "../sections/Footer";

class Page extends React.Component {
    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>

              <Nav current={this.props.location.pathname} />
                <div class="bg-blue-600">
                  <div class="pt-12 sm:pt-16 lg:pt-24">
                    <div class="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                      <div class="max-w-3xl mx-auto lg:max-w-none">
                        <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                          Protocols
                        </h2>
                        <p class="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
                          Flexible Solutions
                        </p>
                        <p class="mt-2 text-xl leading-7 text-gray-300">
                          You can access GLaDOS edge network via V2Ray or Wireguard clients.<br/>
                          Switch based on your needs anytime.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
                    <div class="relative">
                      <div class="absolute inset-0 h-3/4 bg-blue-600"></div>
                      <div class="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div class="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
                          <div class="rounded-lg shadow-lg overflow-hidden">
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                              <div>
                                <span class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                                  Rule Based Routing
                                </span>
                              </div>
                              <div class="mt-4 flex items-baseline text-5xl leading-none font-extrabold">
                                V2RAY
                                <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                  Based Proxy
                                </span>
                              </div>
                              <p class="mt-5 text-lg leading-7 text-gray-500">
                                Powerful Networking tools.
                              </p>
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-200 sm:p-10 sm:pt-6">
                              <ul>
                                <li class="flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                      Support TROJAN/VMESS/VLESS Protocols
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Best solution for personal usage and productivity
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Split Traffic base on Rules.
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Auto selecting best endpoints
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    For Windows, MacOS, iOS, Linux and Android.
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Config file for Clash/Surge/Shadowrocket and more
                                  </p>
                                </li>
                              </ul>
                              <div class="mt-6 rounded-md shadow">
                                <a href="/login" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                  Get started
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
                            <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                              <div>
                                <span class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                                  Full Traffic Routing
                                </span>
                              </div>
                              <div class="mt-4 flex items-baseline text-5xl leading-none font-extrabold">
                                Wireguard
                                <span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
                                  VPN
                                </span>
                              </div>
                              <p class="mt-5 text-lg leading-7 text-gray-500">
                                 state-of-the-art VPN that easy to use
                              </p>
                            </div>
                            <div class="px-6 pt-6 pb-8 bg-gray-200 sm:p-10 sm:pt-6">
                              <ul>
                                <li class="flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Backup solution
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Best for Gaming and Conference
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Full Traffic Routing
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Operating at OSI Layer 3
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Game, Full ICMP/UDP support
                                  </p>
                                </li>
                                <li class="mt-4 flex items-start">
                                  <div class="flex-shrink-0">
                                    <svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
                                    </svg>
                                  </div>
                                  <p class="ml-3 text-base leading-6 text-gray-700">
                                    Fixed Datacenter binding
                                  </p>
                                </li>
                              </ul>
                              <div class="mt-6 rounded-md shadow">
                                <a href="/login" class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-600 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                                  Get started
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              <Service />
              <div class="bg-gray-100">
                <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                  <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    GLaDOS is for Specialists
                  </h2>
                  <h3 class="text-2xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-2xl sm:leading-10">
                    <span class="text-indigo-600">Build for people who cares about tooling</span>
                  </h3>
                  <p>
                    We build fast and reliable services for Professionals, Engineers, Scientists, Doctors, Artists, Elon Musks, Steve Jobs.<br/>
                    They're specialists, not fond of rules. And they have no respect for the status quo.</p>

                  <div class="mt-10 inline-flex rounded-md shadow">
                    <a href="#" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                      Get started
                    </a>
                  </div>
                </div>
              </div>

              <Faq />
              <Footer />

            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
