import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Header from "../sections/Header";
import Feature from "../sections/Feature";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

class Page extends React.Component {

    constructor(){
      super()
    }

    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>
              <Nav current={this.props.location.pathname}  className="bg-gray-800" />

              <div>
                <div className="bg-green-400 pb-32" >
                  <header className="py-10">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                      <h1 className="text-3xl leading-9 font-bold text-white">
                        GLaDOS Status
                      </h1>
                    </div>
                  </header>
                </div>
                <main className="-mt-32">
                  <div className="max-w-6xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
                    <div class="bg-white shadow overflow-hidden sm:rounded-md">
                      <ul>

                        <li>
                          <a href="#" class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                            <div class="px-4 py-4 sm:px-6">
                              <div class="flex items-center justify-between">
                                <div class="text-sm leading-5 font-medium text-indigo-600 truncate">
                                  API Server
                                </div>
                                <div class="ml-2 flex-shrink-0 flex">
                                  <img class="-ml-1 inline-block h-12 w-12 rounded-full text-white shadow-solid" src="https://status.glados.space/static/media/green-check.b9ecb3f7.svg" alt="" />
                                </div>
                              </div>
                              <div class="mt-2 sm:flex sm:justify-between">
                                <div class="mt-2 flex items-center text-sm leading-5 text-gray-500 sm:mt-0">
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </main>
              </div>


              <Footer />
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
