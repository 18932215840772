import React from "react";

const Page = () => (
  <div>

  <div className="bg-blue-100">

    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-16 lg:px-8">

      <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div className="lg:col-span-1">
          <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            7-day money-back guarantee
          </h3>
          <p className="mt-2 text-base leading-6 text-gray-500">
            Try GLaDOS without risk.<br/>Get refund within 7 days. Nothing to ask, no stress.
          </p>
        </div>
        <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
          <div>
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">7-day money back</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                If you’re not satisfied with the speed, security, and convenience of GLaDOS, you can get a refund.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">7/24 Online Help</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Contact support via live chat or email, real humans are available around the clock via email and live chat to help you with setup and troubleshooting.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Netflix and More</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                Watch, listen, stream, and enjoy content from censored and blocked websites around the world, even while traveling.
              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-0">
            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
              <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
              </svg>
            </div>
            <div className="mt-5">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Elastic Payment Options</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
                You can buy membership days as long as you need. Long term subscription will get a big discount.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>










</div>
);

export default Page;
