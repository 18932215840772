import React from "react";
import { connect } from "react-redux";
import { fetchData } from "../../store";
import Nav from "../sections/Nav";
import Header from "../sections/Header";
import Faq from "../sections/Faq";
import Footer from "../sections/Footer";

const items = [{
  display:"Clash For Windows",
  key:"clash-for-windows",
  notes:"",
  github:"",
  url:"https://glados.live/tools/Clash.for.Windows.Setup.0.9.5.exe",
  platform:["Windos 64Bits"],
  opensource:false,
  features:["Popular","Easy to use","Automatic update"],
  price:0,
},{
  display:"ClashX",
  key:"clashx-for-macos",
  notes:"",
  github:"https://github.com/yichengchen/clashX",
  url:"https://glados.live/tools/ClashX.dmg",
  platform:["MacOS"],
  opensource:true,
  features:[
    "Automatic update",
    "HTTP/HTTPS and SOCKS protocol",
    "GeoIP rule support",
    "Support for Netfilter TCP redirecting"
  ],
  price:0,
},{
  display:"Clash",
  key:"clash-core",
  notes:"Clash Core(without GUI)",
  github:"https://github.com/Dreamacro/clash",
  url:"https://github.com/Dreamacro/clash/releases",
  platform:["Linux","Windos","MacOS"],
  opensource:true,
  features:[
    "Ligthweight headless, runs on almost every platform",
    "Local HTTP/HTTPS/SOCKS server with/without authentication",
    "VMess, Shadowsocks, Trojan (experimental), Snell protocol support for remote connections. UDP is supported.",
    "Built-in DNS server that aims to minimize DNS pollution attacks, supports DoH/DoT upstream. Fake IP is also supported.",
    "Rules based off domains, GEOIP, IP CIDR or ports to forward packets to different nodes",
    "Remote groups allow users to implement powerful rules. Supports automatic fallback, load balancing or auto select node based off latency",
    "Remote providers, allowing users to get node lists remotely instead of hardcoding in config",
    "Netfilter TCP redirecting. You can deploy Clash on your Internet gateway with iptables.",
    "Comprehensive HTTP API controller",
  ],
  price:0,
},{
  display:"Shadowrocket",
  key:"shadowrocket",
  notes:"Rule based proxy utility client for iPhone/iPad.",
  github:null,
  url:"https://37apps.net/shop/1001",
  platform:["iOS","iPhone","iPad"],
  opensource:false,
  features:[
    "Capture all HTTP/HTTPS/TCP traffic from any applications on your device, and redirect to the proxy server",
    "Record and display HTTP, HTTPS, DNS requests from your iOS devices",
    "Configure rules using domain match, domain suffix, domain keyword, CIDR IP range, and/or GeoIP lookup",
    "Measure traffic usage and network speed on WiFi, cellular, direct and proxy connections.",
    "Import rule files from URL or iCloud Drive.",
    "Block ads by domain, user agent rules.",
    "Local DNS Mapping.",
    "Work on cellular networks.",
    "Decrypt HTTPS traffic.",
    "Perform URL rewrite.",
    "Fully IPv6 supports.",
    "Script filter supports.",
    "Multi-level forward proxy.",
    "Support kcptun, cloak, gost, v2ray plugins.",
    "Support DoH, DoT."
  ],
  price:2.99,
}]


const Software = (props)=>{
  return (
                  <div className="max-w-6xl mx-auto py-6 sm:px-6 lg:px-8">

                    <div className="max-w-none mx-auto">
                      <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                        <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-no-wrap">
                            <div className="ml-4 mt-2">
                              <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {props.display}
                              </h3>
                              <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                                {props.notes}
                              </p>
                            </div>
                            <div className="ml-4 mt-2 flex-shrink-0">
                              <span className="inline-flex rounded-md shadow-sm">
                                <a href={props.url} type="button" className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700">
                                  Download
                                </a>
                              </span>
                            </div>
                          </div>

                        </div>

                        <div className="bg-white shadow overflow-hidden  sm:rounded-lg">
                          <div className="px-4 py-5 sm:p-0">
                            <dl>
                              <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt className="text-sm leading-5 font-medium text-gray-500">
                                  Platform
                                </dt>
                                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                  {props.platform.join("/")}
                                </dd>
                              </div>
                              <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt className="text-sm leading-5 font-medium text-gray-500">
                                  Opensource
                                </dt>
                                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                  {props.opensource?`yes(${props.github})`:"no"}
                                </dd>
                              </div>
                              <div className="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
                                <dt className="text-sm leading-5 font-medium text-gray-500">
                                  features
                                </dt>
                                <dd className="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
                                  <ul>
                                    {props.features.map((feature)=>{ return (<li>{feature}</li>) })}
                                  </ul>
                                </dd>

                              </div>

                            </dl>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>


  )
}

class Page extends React.Component {

    constructor(props) {
      super();
      this.props = props;
    }

    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>
              <Nav current={this.props.location.pathname} />

              <div className="bg-gray-200" >
                <div className="pb-32 bg-gray-300" >
                  <header className="py-10">
                    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                      <h1 className="text-3xl leading-9 font-bold text-black">
                        Softwares
                      </h1>
                      <p>We recommend theses softwares for connecting GLaDOS endpoints</p>
                    </div>
                  </header>
                </div>
                <main className="-mt-32">
                  {items.map((item)=>{
                    return <Software {...item} />
                  })}
                </main>
              </div>



              <Footer />
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Page );
