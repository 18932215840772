import React from "react";
import Nav from "../sections/Nav";
import Faq from "../sections/Faq";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import Feature from "../sections/Feature";
import Service from "../sections/Service";
import { connect } from "react-redux";
import { fetchData } from "../../store";

class Home extends React.Component {

    constructor(props) {
      super();
      this.props = props;
    }

    componentDidMount( ) {
    }

    render( ) {

        return (
            <div>
              <Nav current={this.props.location.pathname} />
              <Header />
              <Feature />
              <Service />
              <Footer />
            </div>
        );
    }
}

const mapStateToProps = ( state ) => ( { } );

const mapDispatchToProps = { };

export default connect( mapStateToProps, mapDispatchToProps )( Home );
